import { FC } from "react";

import { App, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { FilterOrderList } from "./FilterOrderList";
import { reports } from "../../../services/reports";
import { usePagination } from "../../../hooks";
import { api } from "../../../services/api";
import { formatters } from "../../../utils";
import { ActionTable } from "../../../components/ActionTable";
import { NotFound } from "../../../components/NotFound";

export const getUsers = async (params: any, filter?: any) => {
  const { data } = await api.get<any>(`/reports/technicians`, {
    params: {
      profile: "TECHNICIAN",
      ...params,
      ...filter,
    },
  });

  return { data: data.data, meta: data.meta };
};

const CommissionReport: FC = () => {
  const { message } = App.useApp();

  const {
    data,
    isLoading,
    filter,
    setFilter,
    pagination,
  } = usePagination(getUsers);

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 80,
      render: (value) => value?.toString().padStart(4, "0"),
    },
    {
      title: "Técnico",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Serviços Realizados",
      dataIndex: "meta",
      key: "meta",
      width: 300,
      render: (value) => value.total,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatters.simpleDate(text),
      width: 300,
    },
  ];

  const onPrint = () => {
    console.log(filter);
    if (filter && data.length > 0) {
      reports("simplified-commission", filter);
      return;
    }
    if (filter && data.length === 0) {
      message.info("Não há registros para impressão.");
      return;
    }
    message.error("Você precisa filtar as informações!");
  };

  return (
    <>
      <PageHeader
        title="Relatórios de Comissão Líquida Simplificada"
        backButton={false}
        actions={[
          <Button
            onClick={onPrint}
            key={"print_table"}
            icon={<PrinterOutlined />}
            type="primary"
            disabled={data.length === 0}
          >
            Imprimir
          </Button>,
        ]}
        crumb
      />
      <Content margin>
        <FilterOrderList onSubmit={setFilter} filter={filter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={() => []}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          dataSource={data}
          locale={{
            emptyText: <NotFound />,
          }}
        />
      </Content>
    </>
  );
};

export { CommissionReport };
