import { useState, useEffect, useCallback, useRef, FC } from "react";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router-dom";

import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { api } from "../../../services/api";
import { IEquipment, IMeta, IPagination } from "../../../types";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";

import { PmocEquipmentModal } from "../components/PmocEquipmentModal";

export const getPmocEquipments = async (
  pmocId: string,
  page = 1,
  perPage = 10,
  q?: any
) => {
  return await api.get<IPagination<IEquipment>>(`/pmocs/${pmocId}/equipments`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const PmocEquipments: FC = () => {
  const pmocsTable = useRef<any>();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { id } = useParams();

  const [equipments, setEquipments] = useState<IEquipment[]>([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (refresh?: boolean) => {
    if (refresh) {
      setLoad(!load);
    }
    setIsModalOpen(false);
  };

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      getPmocEquipments(String(id), page, pageSize)
        .then(({ data }) => {
          setEquipments(data?.data);
          setMeta(data?.meta);
          pmocsTable.current?.scrollIntoView({ behavior: "smooth" });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IEquipment> = (record) => {
    return [
      {
        key: "1",
        label: "Detalhar",
        icon: <SearchOutlined />,
        onClick: () => navigate(`${record.id}`),
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleShowModal(),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        // onClick: () => onDeleteItem(record),
      },
    ];
  };

  const columns: ColumnsType<IEquipment> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value.match(/\d/g).slice(0, 4).join("")}
          </div>
        );
      },
      width: 80,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Local",
      dataIndex: "local",
      key: "local",
      render: (data) => data?.name ?? "--",
    },
    {
      title: "Unidade",
      dataIndex: "unit",
      key: "unit",
      render: (data) => data?.name ?? "--",
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
    },
    ActionColumn(actions),
  ];

  return outlet ? (
    <Outlet />
  ) : (
    <Content width="calc(100% - 48px)">
      <PmocEquipmentModal
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
      />
      <PageHeader
        backButton={false}
        title={"Equipamentos do PMOC"}
        actions={[
          <Button
            key={"add_pmoc_equipment"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleShowModal()}
          >
            Adicionar Equipmento
          </Button>,
        ]}
      />
      <Table
        ref={pmocsTable}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          current: meta.current_page,
          pageSize: meta.per_page,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record) => record.id}
        dataSource={equipments}
        columns={columns}
      />
    </Content>
  );
};

export { PmocEquipments };
