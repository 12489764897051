import { StatusTag } from "../../../components/StatusTag";
import { OrderStatus } from "../../../types";

export const colors = {
  CANCELLED: { name: "CANCELADO", color: "red" },
  REQUESTED: { name: "REQUISITADO", color: "lime" },
  PENDING: { name: "PENDENTE", color: "orange" },
  OPEN: { name: "ABERTO", color: "green" },
  REOPENED: { name: "REABERTO", color: "cyan" },
  WAITING: { name: "AGUARDANDO", color: "yellow" },
  EXECUTION: { name: "EXECUÇÃO", color: "blue" },
  DELAYED: { name: "ATRASADO", color: "red" },
  FINALIZED: { name: "FINALIZADO", color: "default" },
  VALIDATED: { name: "VALIDADO", color: "purple" },
};

interface StatusTagsProps {
  status: OrderStatus;
  size?: "full";
}

const ServiceOrderStatusTag = ({ status, size }: StatusTagsProps) => {
  return (
    <StatusTag size={size ? size : undefined} color={colors[status].color}>
      {colors[status].name}
    </StatusTag>
  );
};

export { ServiceOrderStatusTag };
