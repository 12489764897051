import { useState, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { App, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  CameraOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";

import { api } from "../../../services/api";
import { IItem } from "../../../types";

import { PageHeader } from "../../../components/PageHeader";
import { formatters } from "../../../utils/formatters";
import { ItemModal } from "../components/ServiceItemModal";
import { usePagination } from "../../../hooks/useNewPagination";
import { getServiceItems } from "../../../services/repositories";
import { ActionTable } from "../../../components/ActionTable";

const OrderServiceItems: FC = () => {
  const { modal, message } = App.useApp();

  const { id, serviceId } = useParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceItem, setServiceItem] = useState<IItem>();

  const {
    data: serviceItems,
    isLoading,
    pagination,
    handleRefreshData,
  } = usePagination(getServiceItems);

  const actions = (record: IItem) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleShowModal(record),
      },
      {
        key: "2",
        icon: <CameraOutlined />,
        label: "Fotos",
        onClick: () => {
          navigate(
            `/orders/${id}/services/${serviceId}/photos/${record.meta?.pivot_id}`
          );
        },
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => handleDeleteItem(record),
      },
    ];
  };

  const handleShowModal = (record?: IItem) => {
    if (record) {
      setServiceItem(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = (refresh?: boolean) => {
    if (refresh) {
      handleRefreshData();
    }
    setIsModalOpen(false);
    setServiceItem(undefined);
  };

  const handleDeleteItem = (record: IItem) => {
    modal.confirm({
      title: "Deseja excluir esse item?",
      icon: <ExclamationCircleFilled />,
      content: "Atenção! essa ação é irreversivel.",
      okType: "danger",
      async onOk() {
        return await api
          .delete(
            `/service-orders/${id}/services/${serviceId}/items/${record.id}`
          )
          .then(() => {
            message.success("Item deletado com sucesso!");
            handleRefreshData();
          })
          .catch(() => message.error("Algo inesperado ocorreu!"));
      },
    });
  };

  const columns: ColumnsType<IItem> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 80,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Unidade",
      dataIndex: "unit",
      key: "unit",
      ellipsis: true,
      width: 120,
    },
    {
      title: "Quantidade",
      dataIndex: "meta",
      key: "quantity",
      width: 120,
      render: ({ pivot_quantity }) =>
        pivot_quantity.toString().replace(".", ","),
    },
    {
      title: "Valor",
      dataIndex: "meta",
      key: "value",
      render: ({ pivot_value }) => formatters.currency(pivot_value),
      width: 120,
    },
    {
      title: "Custo",
      dataIndex: "meta",
      key: "cost",
      render: ({ pivot_cost }) => formatters.currency(pivot_cost),
      width: 120,
    },
  ];

  return (
    <>
      <ItemModal
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
        data={serviceItem}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Itens"}
        actions={[
          <Button
            key={"add_item"}
            type="primary"
            onClick={() => handleShowModal()}
            icon={<PlusOutlined />}
          >
            Adicionar Item
          </Button>,
        ]}
      />
      <ActionTable
        actions={actions}
        pagination={pagination}
        dataSource={serviceItems}
        loading={isLoading}
        columns={columns}
      />
    </>
  );
};

export { OrderServiceItems };
