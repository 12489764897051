export const NotFound = () => {
  return (
    <div style={{ textAlign: "center", padding: "40px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="110"
        height="94"
        fill="none"
        viewBox="0 0 110 94"
      >
        <path
          fill="#367CFF"
          d="M109.496 64.81c-3.005-15.186-20.606-27.323-24.04-43.147C79.216 1.529 58.651-1.654 45.286 7.09 24.111 20.945 46.701 24.383 5.182 57.163-5.07 65.257-1.803 100.986 30.45 92.786c17.356-4.413 22.633-5.032 30.44-5.759 19.678-1.83 53.65 3.275 48.607-22.217"
          opacity="0.1"
        ></path>
        <path
          fill="#fff"
          d="M85.988 68.616 55.574 84.718 25.205 68.616V44.302h60.783z"
        ></path>
        <path
          fill="#262626"
          d="M55.574 84.964q-.061 0-.116-.028L25.091 68.834a.25.25 0 0 1-.132-.217V44.302a.247.247 0 0 1 .247-.247h60.782a.246.246 0 0 1 .246.247v24.314a.25.25 0 0 1-.131.218L55.689 84.935a.25.25 0 0 1-.115.03M25.45 68.468l30.123 15.97 30.168-15.97V44.549h-60.29z"
        ></path>
        <path
          fill="#fff"
          d="M85.988 39.085 55.573 22.984 69.24 16.38l30.415 16.1z"
        ></path>
        <path
          fill="#262626"
          d="M85.988 39.332q-.061 0-.115-.029L55.458 23.202a.248.248 0 0 1 .008-.441l13.667-6.603a.24.24 0 0 1 .223.004L99.77 32.263a.246.246 0 0 1 .092.352.25.25 0 0 1-.1.09l-13.667 6.602a.24.24 0 0 1-.107.025M56.12 22.993l29.873 15.815 13.115-6.337-29.873-15.814z"
        ></path>
        <path
          fill="#fff"
          d="M25.205 39.085 55.62 22.984 41.952 16.38l-30.414 16.1z"
        ></path>
        <path
          fill="#262626"
          d="M25.205 39.332a.25.25 0 0 1-.107-.025l-13.666-6.603a.248.248 0 0 1-.008-.44l30.414-16.102a.25.25 0 0 1 .222-.004l13.667 6.603a.246.246 0 0 1 .105.348.25.25 0 0 1-.097.093L25.321 39.303a.25.25 0 0 1-.116.029m-13.12-6.86L25.2 38.808l29.873-15.814-13.115-6.337z"
        ></path>
        <path
          fill="#fff"
          d="m25.205 39.085 30.414 16.101-15.638 8.579L9.567 47.664z"
        ></path>
        <path
          fill="#262626"
          d="M39.981 64.012q-.061 0-.115-.029L9.452 47.882a.247.247 0 0 1-.003-.435l15.638-8.579a.25.25 0 0 1 .233-.001l30.414 16.101a.247.247 0 0 1 .003.435L40.1 63.98a.25.25 0 0 1-.118.03M10.087 47.66 39.98 63.484l15.12-8.294-29.892-15.825z"
        ></path>
        <path
          fill="#fff"
          d="M85.988 39.085 55.573 55.186l15.638 8.579 30.415-16.101z"
        ></path>
        <path
          fill="#262626"
          d="M71.212 64.012a.25.25 0 0 1-.118-.03l-15.639-8.58a.247.247 0 0 1 .004-.434l30.414-16.101a.25.25 0 0 1 .233.001l15.638 8.58a.246.246 0 0 1-.003.435l-30.414 16.1a.24.24 0 0 1-.115.029M56.094 55.19l15.12 8.294 29.892-15.824-15.12-8.295z"
        ></path>
        <path
          fill="#262626"
          d="m55.62 22.984-30.415 16.1 30.369 16.102 30.414-16.101z"
        ></path>
        <path fill="#262626" d="M55.82 55.186h-.493v29.532h.493z"></path>
        <path
          fill="#292A2E"
          d="M38.533 37.623a.197.197 0 0 1-.16-.312c.036-.051 3.598-5.213-.379-9.92a7.5 7.5 0 0 0-5.734-2.67h-.054c.378 1.315.31 2.485-.225 3.381a2.5 2.5 0 0 1-2.302 1.269 1.98 1.98 0 0 1-1.71-1.334c-.438-1.144.02-2.252 1.224-2.967a5.8 5.8 0 0 1 2.481-.715 10.5 10.5 0 0 0-1.097-2.138c-1.436-2.198-4.053-3.72-7.18-4.175-2.65-.385-5.19.069-6.471 1.156a.197.197 0 0 1-.324-.167c.005-.052.03-.1.07-.134 1.363-1.158 4.025-1.647 6.782-1.245 3.239.472 5.955 2.057 7.453 4.35.486.727.88 1.51 1.177 2.334a7.86 7.86 0 0 1 6.21 2.801c4.177 4.943.437 10.35.399 10.404a.2.2 0 0 1-.16.082m-6.73-12.887a5.5 5.5 0 0 0-2.408.674c-1.04.617-1.425 1.523-1.057 2.486a1.6 1.6 0 0 0 1.376 1.081 2.1 2.1 0 0 0 1.93-1.077c.49-.824.536-1.919.158-3.164M13.525 22.384a1.34 1.34 0 0 1-1.661-.867 1.35 1.35 0 0 1 .82-1.687 1.34 1.34 0 0 1 1.705.777 1.35 1.35 0 0 1-.864 1.777m-.656-2.195a.945.945 0 0 0-.607 1.25.946.946 0 0 0 1.2.546.945.945 0 0 0 .577-1.188.946.946 0 0 0-1.17-.608M13.522 19.288a1.337 1.337 0 0 1-1.62-.768 1.35 1.35 0 0 1 .285-1.465 1.34 1.34 0 0 1 2.292.94 1.35 1.35 0 0 1-.957 1.293m-.655-2.195a.947.947 0 0 0-.6 1.275.948.948 0 0 0 1.369.44.947.947 0 0 0 .22-1.425.95.95 0 0 0-.989-.29"
        ></path>
        <path
          fill="#fff"
          d="M13.14 20.685c.967 0 1.752-.525 1.752-1.173s-.785-1.173-1.753-1.173-1.752.525-1.752 1.173.784 1.173 1.752 1.173"
        ></path>
        <path
          fill="#292A2E"
          d="M13.915 20.772a2.7 2.7 0 0 1-.774.11c-1.093 0-1.951-.6-1.951-1.368 0-.77.855-1.372 1.948-1.373.477-.01.946.121 1.35.375a1.12 1.12 0 0 1 .44 1.575 1.1 1.1 0 0 1-.439.413q-.267.176-.574.267m-1.431-2.142c-.527.158-.9.5-.9.883 0 .528.713.975 1.557.974.399.008.792-.1 1.13-.311a.73.73 0 0 0 .309-1.061.73.73 0 0 0-.31-.27 2.05 2.05 0 0 0-1.13-.31q-.335 0-.656.095"
        ></path>
        <path
          fill="#292A2E"
          d="m13.57 20.65-.042-2.282-.566-.023-.04 2.331zM10.814 20.008a.181.181 0 1 0 0-.362.181.181 0 0 0 0 .362M10.955 19.038a.181.181 0 1 0 0-.362.181.181 0 0 0 0 .362M69.502 26.23a.2.2 0 0 1-.188-.14 19.7 19.7 0 0 1-.514-7.512c.51-3.674 2.531-8.497 9.27-10.609.948-.305 1.92-.529 2.906-.668.318-1.987 1.526-4.144 4.227-5.631 6.418-3.535 10.753-.364 10.796-.331a.198.198 0 0 1-.236.316c-.041-.03-4.184-3.046-10.37.361-2.524 1.39-3.68 3.386-4.008 5.238 2.972-.293 4.395.7 5.034 1.451a3.06 3.06 0 0 1 .456 3.346 2.39 2.39 0 0 1-2.332 1.365c-1.66-.097-3.034-1.497-3.5-3.565a6.7 6.7 0 0 1-.12-2.134c-.928.134-1.843.344-2.736.63-5.251 1.646-8.278 5.107-8.997 10.287a19.2 19.2 0 0 0 .5 7.341.2.2 0 0 1-.03.176.2.2 0 0 1-.158.08zM81.325 7.668c-.083.7-.048 1.408.103 2.096.426 1.892 1.658 3.17 3.138 3.258a2 2 0 0 0 1.956-1.146 2.67 2.67 0 0 0-.403-2.914c-.922-1.084-2.604-1.522-4.794-1.294M100.591 3.336a1.4 1.4 0 0 1-1.268-.804 1.414 1.414 0 0 1 .815-1.942 1.405 1.405 0 0 1 1.831 1.035 1.416 1.416 0 0 1-1.378 1.71m.003-2.427a1.015 1.015 0 1 0 .932 1.413 1.02 1.02 0 0 0-.295-1.19 1 1 0 0 0-.637-.223M99.997 6.56a1.41 1.41 0 0 1-1.41-1.339 1.417 1.417 0 0 1 1.259-1.483 1.41 1.41 0 0 1 1.544 1.18 1.42 1.42 0 0 1-1.092 1.61 1.4 1.4 0 0 1-.301.032m.005-2.427q-.11 0-.217.024a1.01 1.01 0 0 0-.778 1.149 1.01 1.01 0 0 0 1.103.841 1.007 1.007 0 0 0 .897-1.058 1.007 1.007 0 0 0-1.005-.956"
        ></path>
        <path
          fill="#fff"
          d="M102.114 3.913c.124-.675-.592-1.373-1.6-1.56-1.008-.186-1.926.21-2.05.885-.124.674.592 1.373 1.6 1.56 1.008.186 1.926-.21 2.05-.885"
        ></path>
        <path
          fill="#292A2E"
          d="M100.56 5.041a3 3 0 0 1-.532-.05 2.6 2.6 0 0 1-1.326-.649 1.27 1.27 0 0 1-.432-1.14 1.27 1.27 0 0 1 .81-.91 2.74 2.74 0 0 1 2.796.517 1.27 1.27 0 0 1 .432 1.14 1.27 1.27 0 0 1-.81.91 2.4 2.4 0 0 1-.938.182m-.544-2.536a2 2 0 0 0-.78.15.89.89 0 0 0-.578.619.89.89 0 0 0 .319.785 2.36 2.36 0 0 0 2.365.438.89.89 0 0 0 .577-.62.9.9 0 0 0-.06-.433.9.9 0 0 0-.259-.352 2.2 2.2 0 0 0-1.123-.544 2.6 2.6 0 0 0-.46-.043z"
        ></path>
        <path
          fill="#292A2E"
          d="m100.061 2.308-.396 2.384.584.133.491-2.42zM102.437 4.873a.192.192 0 0 0 0-.385.19.19 0 0 0-.191.192c0 .107.085.193.191.193M102.771 3.89a.192.192 0 0 0 0-.385.19.19 0 0 0-.191.192c0 .106.085.192.191.192"
        ></path>
      </svg>
      <h3
        style={{
          marginBottom: "8px",
          color: "#434343",
          fontSize: "18px",
          paddingTop: "10px",
        }}
      >
        Dados não encontrados!
      </h3>
      <p style={{ color: "#4c4c4c" }}>
        Nenhum resultado encontrado para o filtro aplicado. <br />
        Tente refazer a busca.
      </p>
    </div>
  );
};
