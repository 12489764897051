import { RouteObject } from "react-router-dom";
import { ErrorPage } from "../error";

import { Orders } from "./list";
import { ServiceOrder } from "./order";
import { OrderService } from "./order/OrderService";
import { OrderServices } from "./order/OrderServices";
import { OrderServiceItems } from "./order/OrderServiceItems";
import { OrderServicePhotos } from "./order/OrderServicePhotos";
import { OrderTimeLine } from "./order/OrderTimeLine";
import { OrderServiceProducts } from "./order/OrderServiceProducts";

import { loader } from "../../utils/loader";
import { ServiceOrderProvider } from "../../contexts/OrderContext";

import { api } from "../../services/api";
import { IService, IServiceOrder } from "../../types";
import { OrderServiceActivities } from "./order/OrderServiceActivities";
import { OrderServiceFiles } from "./order/OrderServiceFiles";

const OrderRouter: RouteObject = {
  path: "/orders",
  element: <ServiceOrderProvider />,
  handle: { crumb: () => "Atendimentos" },
  children: [
    {
      index: true,
      loader: loader,
      element: <Orders />,
    },
    {
      path: ":id",
      element: <ServiceOrder />,
      loader: async ({ params }) => {
        const { data } = await api.get<IServiceOrder>(
          `/service-orders/${params.id}`
        );
        return data;
      },
      handle: {
        crumb: (data?: IServiceOrder) => data?.sid,
      },
      children: [
        {
          path: "services",
          handle: {
            crumb: () => "Serviços",
          },
          children: [
            {
              index: true,
              element: <OrderServices />,
              handle: { crumb: () => "Serviços" },
            },
            {
              path: ":serviceId",
              element: <OrderService />,
              loader: async ({ params }) => {
                const { data } = await api.get<IService>(
                  `/service-orders/${params.id}/services/${params.serviceId}`
                );
                return data;
              },
              handle: {
                crumb: (data?: IService) => data?.description,
              },
              children: [
                {
                  path: "activities",
                  element: <OrderServiceActivities />,
                  handle: { crumb: () => "Atividades" },
                },
                {
                  path: "items",
                  element: <OrderServiceItems />,
                  handle: { crumb: () => "Items" },
                },
                {
                  path: "products",
                  element: <OrderServiceProducts />,
                  handle: { crumb: () => "Produtos" },
                },
                {
                  path: "photos",
                  element: <OrderServicePhotos />,
                  handle: { crumb: () => "Fotos" },
                },
                {
                  path: "photos/:serviceItemId",
                  element: <OrderServicePhotos />,
                  handle: { crumb: () => "Fotos" },
                },
              ],
            },
          ],
        },
        {
          path: "time-line",
          handle: { crumb: () => "Linha do Tempo" },
          element: <OrderTimeLine />,
        },
        {
          path: "files",
          element: <OrderServiceFiles />,
          handle: { crumb: () => "Arquivos" },
        },
      ],
    },
  ],
  errorElement: <ErrorPage />,
};

export { OrderRouter };
