import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { FC, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message as Message,
  Modal,
  Select,
  Switch,
  Upload,
  UploadProps,
  notification,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { TOKEN_API, api } from "../../../services/api";
import { formatters, normFile, parsers } from "../../../utils";
import {
  ChartOfAccountSon,
  Financial,
  IBankAccount,
  IClient,
  IContract,
  ICostCenter,
  IPayment,
  ISupplier,
  ModalProps,
} from "../../../types";
import {
  getAllBankAccount,
  getClients,
  getSuppliers,
} from "../../../services/repositories";
import { getAllContracts } from "../../contracts/list";

import {
  getChartOfAccountSons,
  getCostCenter,
  getPaymentMethod,
} from "./FinancialModal";

dayjs.extend(utc);

const UpdateFinancialModal: FC<ModalProps<Financial>> = ({
  onClose,
  isModalOpen,
  onRefreshData,
  data,
}) => {
  const [message] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);
  const [chartOfAccountSons, setChartOfAccountSons] =
    useState<ChartOfAccountSon[]>();
  const [costCenter, setCostCenter] = useState<ICostCenter[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<IPayment[]>([]);
  const [installments, setInstallments] = useState<number[]>([]);
  const [competenceDate, setCompetenceDate] = useState<Dayjs | null>(null);

  const handleValueChange = (value: number | null) => {
    if (value === null) {
      setInstallments([]);
      return;
    }

    const newInstallments = [];
    for (let i = 1; i <= 12; i++) {
      newInstallments.push(value / i);
    }
    setInstallments(newInstallments);
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const fetchContracts = async (clientId: string | null) => {
    try {
      const { data } = await getAllContracts(1, 1000, { clientId });
      setContracts(data?.data);
    } catch (error) {
      console.error("Erro ao buscar contratos:", error);
    }
  };

  const handleClientChange = (value: string) => {
    fetchContracts(value);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        await getAllBankAccount({
          page: 1,
          perPage: 1000,
        }).then(({ data }) => {
          setBankAccounts(data);
        });
        await getChartOfAccountSons(1, 1000).then(({ data }) => {
          setChartOfAccountSons(data?.data);
        });
        await getCostCenter(1, 1000).then(({ data }) => {
          setCostCenter(data?.data);
        });
        await getClients(1, 1000).then(({ data }) => {
          setClients(data);
        });
        await getSuppliers(1, 1000).then(({ data }) => {
          setSuppliers(data);
        });
        await getPaymentMethod(1, 1000).then(({ data }) => {
          setPaymentMethod(data.data);
        });
      } catch (error) {
        console.error(error);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (data) {
        fetchContracts(data.item.client_id);
        form.setFieldsValue({
          ...data.item,
          contact: data.item.client ? "CLIENT" : "SUPPLIER",
          clientId: data.item.client_id,
          contractId: data.item.contract_id,
          supplierId: data.item.supplier_id,
          paymentType: data.item.payment_type,
          installmentQuantity: `${data.totalInstallments}x de ${formatCurrency(
            parseFloat(data.item.value) / data.totalInstallments
          )}`,
          paymentMethodId: data.item.payment_method_id,
          automaticLiquidation: data.item.automatic_liquidation,
          bankAccountId: data.item.bank_account_id,
          chartOfAccountSonsId: data.item.chart_of_account_sons_id,
          costCenterId: data.item.cost_center_id,
        });

        if (data.item.competence) {
          form.setFieldValue("competence", dayjs.utc(data.item.competence));
        }
        if (data.item.due) {
          form.setFieldValue("due", dayjs.utc(data.item.due));
        }

        const fileData = (await api.get(`/financials/files/${data.item.id}`))
          .data.data;

        const fileList = fileData.map((file: any) => ({
          uid: file.id,
          name: file.name,
          status: "done",
          url: file.url,
        }));

        form.setFieldsValue({ fileList });
      }
    };
    if (isModalOpen) {
      getData().finally(() => setLoading(false));
    }
  }, [form, data, isModalOpen]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    setFiles([]);
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const formValues = await form
      .validateFields()
      .then((values) => {
        const formValues = {
          type: values.type,
          document: values.document,
          clientId: values.clientId,
          supplierId: values.supplierId,
          contractId: values.contractId,
          due: values.due ?? "",
          competence: values.competence,
          description: values.description,
          value: values.value,
          bankAccountId: values.bankAccountId,
          costCenterId: values.costCenterId,
          paymentType: values.paymentType,
          chartOfAccountSonsId: values.chartOfAccountSonsId,
          automaticLiquidation: values.automaticLiquidation,
        };
        return formValues;
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!formValues) {
      return;
    }

    setLoading(true);
    await api
      .put(`/financials/update/${data?.item?.id}`, formValues)
      .then(async () => {
        if (onRefreshData) {
          onRefreshData();
        }
        notification.success({
          message: "Sucesso",
          description: "Conta atualizada com sucesso!",
        });
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            notification.error({
              message: "Erro",
              description: err.message,
            })
          );
        } else {
          notification.error({
            message: "Erro",
            description: "Algo inesperado ocorreu!",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    if (files) {
      await handleSubmitFiles(files, data?.item?.id);
    }
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    data: {
      folder: "financial_files",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFiles((prevFiles) => {
          const newFiles = info.fileList
            .map((file) => file.response)
            .filter((file) => file !== undefined);

          const uniqueFiles = newFiles.filter(
            (newFile) =>
              !prevFiles.some(
                (existingFile) => existingFile.name === newFile.name
              )
          );

          return [...prevFiles, ...uniqueFiles];
        });
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(`${info.file.name} - ${err.message}`)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    onRemove(file) {
      if (data) {
        deleteFile(file.uid);
      }
      setFiles((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
    },
    accept:
      "image/*,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    listType: "picture",
    maxCount: 5,
  };

  const handleSubmitFiles = async (
    files: any[],
    financialId: string | undefined
  ) => {
    return await api.post(`/financials/files/${financialId}`, {
      files,
    });
  };

  const deleteFile = async (id: string) => {
    try {
      await api.delete(`/financials/files/${id}`).then(() => {
        message.success("Arquivo removido com sucesso.");
      });
    } catch (error) {
      message.error("Erro ao remover o arquivo.");
    }
  };

  return (
    <Modal
      title="Cadastrar conta"
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          disabled={loading}
          danger
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="financial_update_form_modal">
        <Form.Item name="type" label="Tipo de conta">
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
            disabled
          >
            <Select.Option value="PAYMENT">Contas a pagar</Select.Option>
            <Select.Option value="RECEIPT">Contas a receber</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="document" label="Número do documento">
          <Input />
        </Form.Item>
        <Form.Item
          name="contact"
          label="Contato"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            <Select.Option value="CLIENT">Cliente</Select.Option>
            <Select.Option value="SUPPLIER">Fornecedor</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const contactType = form.getFieldValue("contact");
            if (contactType === "CLIENT") {
              return (
                <>
                  <Form.Item
                    name="clientId"
                    label="Cliente"
                    rules={[
                      ({ getFieldValue }) => ({
                        required: getFieldValue("contact") === "CLIENT",
                        message: "Campo obrigatório!",
                      }),
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                      onChange={handleClientChange}
                    >
                      {clients?.map((client) => (
                        <Select.Option value={client.id} key={client.id}>
                          {client.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="contractId" label="Contrato">
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                      disabled={!form.getFieldValue("clientId")}
                    >
                      {contracts?.map((contract) => (
                        <Select.Option value={contract.id} key={contract.id}>
                          {contract.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              );
            }
            if (contactType === "SUPPLIER") {
              return (
                <>
                  <Form.Item
                    name="supplierId"
                    label="Fornecedor"
                    rules={[
                      ({ getFieldValue }) => ({
                        required: getFieldValue("contact") === "SUPPLIER",
                        message: "Campo obrigatório!",
                      }),
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                    >
                      {suppliers?.map((supplier) => (
                        <Select.Option value={supplier.id} key={supplier.id}>
                          {supplier.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          name="competence"
          label="Data de competência"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            style={{ width: "100%" }}
            disabledDate={(currentDate) =>
              currentDate && currentDate > dayjs().endOf("day")
            }
            onChange={(date) => setCompetenceDate(date)}
          />
        </Form.Item>
        <Form.Item
          name="due"
          label="Data de vencimento"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            style={{ width: "100%" }}
            disabledDate={(currentDate) => {
              if (!competenceDate) return false;
              return currentDate < competenceDate;
            }}
          />
        </Form.Item>
        <Form.Item name="description" label="Descrição">
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="value"
          label="Valor"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <InputNumber
            formatter={formatters.currency}
            min={0.1}
            parser={parsers.currency}
            onChange={handleValueChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="paymentType"
          label="Cobrança"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
            disabled
          >
            <Select.Option value="SINGLE">Única</Select.Option>
            <Select.Option value="INSTALLMENT">Parcelado</Select.Option>
            <Select.Option value="RECURRING">Recorrente</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const paymentType = form.getFieldValue("paymentType");
            if (paymentType === "INSTALLMENT") {
              return (
                <>
                  <Form.Item
                    name="installmentQuantity"
                    label="Parcelas"
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          getFieldValue("paymentType") === "INSTALLMENT",
                        message: "Campo obrigatório!",
                      }),
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                      disabled
                    >
                      {installments.map((installment, index) => (
                        <Select.Option key={index} value={index + 1}>
                          {`${index + 1}x de ${formatCurrency(installment)}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="frequency"
                    label="Frequência"
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          getFieldValue("paymentType") === "INSTALLMENT",
                        message: "Campo obrigatório!",
                      }),
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      placeholder="Selecione uma opção"
                      disabled
                    >
                      <Select.Option value="FORTNIGHTLY">
                        quinzenal
                      </Select.Option>
                      <Select.Option value="MONTHLY">mensal</Select.Option>
                      <Select.Option value="BIMONTHLY">
                        bimenstral
                      </Select.Option>
                      <Select.Option value="QUARTERLY">
                        trimestral
                      </Select.Option>
                      <Select.Option value="SEMIANNUAL">
                        semestral
                      </Select.Option>
                      <Select.Option value="YEARLY">anual</Select.Option>
                    </Select>
                  </Form.Item>
                </>
              );
            }
            if (paymentType === "RECURRING") {
              return (
                <Form.Item
                  name="frequency"
                  label="Frequência"
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue("paymentType") === "RECURRING",
                      message: "Campo obrigatório!",
                    }),
                  ]}
                >
                  <Select
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    placeholder="Selecione uma opção"
                    disabled
                  >
                    <Select.Option value="FORTNIGHTLY">quinzenal</Select.Option>
                    <Select.Option value="MONTHLY">mensal</Select.Option>
                    <Select.Option value="BIMONTHLY">bimenstral</Select.Option>
                    <Select.Option value="QUARTERLY">trimestral</Select.Option>
                    <Select.Option value="SEMIANNUAL">semestral</Select.Option>
                    <Select.Option value="YEARLY">anual</Select.Option>
                  </Select>
                </Form.Item>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          name="paymentMethodId"
          label="Forma de pagamento"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
            disabled
          >
            {paymentMethod?.map((paymentMethod) => (
              <Select.Option value={paymentMethod.id} key={paymentMethod.id}>
                {paymentMethod.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="automaticLiquidation" label="Pagamento liquidado">
          <Switch checkedChildren="SIM" unCheckedChildren="NÃO" />
        </Form.Item>
        <Form.Item
          name="bankAccountId"
          label="Conta de operação"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {bankAccounts?.map((bankAccount) => (
              <Select.Option value={bankAccount.id} key={bankAccount.id}>
                {bankAccount.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="chartOfAccountSonsId" label="Plano de contas">
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {chartOfAccountSons?.map((chartOfAccount) => (
              <Select.Option value={chartOfAccount.id} key={chartOfAccount.id}>
                {chartOfAccount.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="costCenterId" label="Centro de custos">
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            placeholder="Selecione uma opção"
          >
            {costCenter?.map((costCenter) => (
              <Select.Option value={costCenter.id} key={costCenter.id}>
                {costCenter.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Anexar documentos"
          name="fileList"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload.Dragger name="file" {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Clique ou arraste aqui para fazer upload
            </p>
            <p className="ant-upload-hint">
              Arquivos que podem ser anexados: (PDF, PNG, JPG, Word, Excel)
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { UpdateFinancialModal };
