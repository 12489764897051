import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  BarChartOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Content } from "../../../components/Content";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";

import { IUser } from "../../../types";
import { getUsers } from "../../users/list";
import { api } from "../../../services/api";
import { UserLocalModal } from "../components/UserLocalsModal";
import { UserModal } from "../components/UserModal";
import { UserUpdateModal } from "../components/UserUpdateModal";

const ClientUsers: React.FC = () => {
  const { id } = useParams();

  const [users, setUsers] = useState<IUser[]>([]);
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<any>({
    current_page: 1,
    per_page: 10,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isOpenLocals, setIsOpenLocals] = useState(false);

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getUsers(page, pageSize, { clientId: id })
        .then(({ data }) => {
          setUsers(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id]
  );

  useEffect(() => {
    handleChangePage(1, 10);
  }, [handleChangePage, load]);

  const handleShowUpdateModal = (record?: IUser) => {
    if (record) {
      setUser(record);
    }
    setIsUpdateOpen(true);
  };

  const handleShowLocalsModal = (record: IUser) => {
    setUser(record);
    setIsOpenLocals(true);
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const actions: ActionFunction<IUser> = (record) => {
    const options = [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleShowUpdateModal(record),
        conditional: true,
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Locais",
        onClick: () => handleShowLocalsModal(record),
        conditional: true,
      },
      {
        key: "3",
        icon: <BarChartOutlined />,
        label: "Re-enviar Email",
        onClick: async () => {
          await api.put(`/users/${record.id}/resend-email`);
        },
        conditional: !record.isValidated,
      },
      {
        key: "4",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        conditional: true,
      },
    ].filter((item) => item.conditional);
    return options;
  };

  const columns: ColumnsType<IUser> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 80,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Nome de Usuário",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "account",
      key: "email",
      render: (account) => account?.email,
      ellipsis: true,
    },
    {
      title: "Cargo",
      dataIndex: "profile",
      key: "profile",
      render: (profile) => (profile === "CUSTOMER" ? "USUÁRIO" : "GERENTE"),
    },
    ActionColumn(actions),
  ];

  return (
    <Content width="calc(100% - 48px)">
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Usuários da Central do Cliente"}
        actions={[
          <Button
            key={"add_client_local"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsOpen(true)}
          >
            Adicionar Usuário
          </Button>,
        ]}
      />
      <Table
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          pageSize: meta.per_page,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={users}
      />
      <UserLocalModal
        isModalOpen={isOpenLocals}
        onClose={() => setIsOpenLocals(false)}
        data={user}
      />
      <UserUpdateModal
        onRefreshData={() => setLoad(!load)}
        onClose={() => setIsUpdateOpen(false)}
        handleRefreshData={handleRefreshData}
        isModalOpen={isUpdateOpen}
        data={user}
      />
      <UserModal
        onRefreshData={() => setLoad(!load)}
        onClose={() => setIsOpen(false)}
        handleRefreshData={handleRefreshData}
        isModalOpen={isOpen}
        clientId={id}
      />
    </Content>
  );
};

export { ClientUsers };
