import { format, isValid, parse, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

const formatters = {
  phone(value: string | undefined) {
    if (!value) {
      return "";
    }
    value = value.replace(/\D/g, "");
    value = value.replace(/^0/, "");
    if (value.length > 10) {
      value = value.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    }
    if (value.length > 6) {
      value = value.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    }
    if (value.length > 2) {
      value = value.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      value = value.replace(/^(\d*)/, "($1");
    }

    return value;
  },

  // simpleDate(date: string | undefined) {
  //   if (!date) {
  //     return "--";
  //   }
  //   try {
  //     const formattedDate = format(parseISO(date), "dd/MM/yyy", {
  //       locale: ptBR,
  //     });
  //     return formattedDate;
  //   } catch {
  //     return "error!";
  //   }
  // },

  simpleDate(date: string | undefined): string {
    if (!date) {
      return "--";
    }
    try {
      const [dateOnly] = date.split("T");

      const formattedDate = parse(dateOnly, "yyyy-MM-dd", new Date());

      if (!isValid(formattedDate)) {
        return "error!";
      }

      return format(formattedDate, "dd/MM/yyyy", { locale: ptBR });
    } catch {
      return "error!";
    }
  },

  customDate(date: string | undefined, pattern: string) {
    if (!date) {
      return "--";
    }
    try {
      const formattedDate = format(parseISO(date), pattern, {
        locale: ptBR,
      });
      return formattedDate;
    } catch {
      return "error!";
    }
  },

  currency(value?: string | number) {
    if (!value) {
      return "0";
    }
    var tmp = Number(value);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(tmp);
  },

  identifier(text?: string) {
    if (!text) {
      return "";
    }
    text = text.replace(/\D/g, "");
    if (text.length > 11) {
      text = text.replace(/^(\d{2})(\d)/, "$1.$2");
      text = text.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      text = text.replace(/\.(\d{3})(\d)/, ".$1/$2");
      text = text.replace(/(\d{4})(\d)/, "$1-$2");

      return text;
    } else {
      text = text.replace(/(\d{3})(\d)/, "$1.$2");
      text = text.replace(/(\d{3})(\d)/, "$1.$2");
      text = text.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

      return text;
    }
  },
};

export { formatters };
