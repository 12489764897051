import { useState, useEffect, useRef, useCallback, FC } from "react";
import { useParams } from "react-router-dom";

import { message, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";

import { PDF, PNG, JPG } from "../../../assets/icons";

import { api } from "../../../services/api";
import { Content } from "../../../components/Content";
import { IMeta, IOrderServiceFile, IPagination } from "../../../types";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { downloader, formatters } from "../../../utils";

const getOrderServiceFiles = async (
  serviceOrderId: string,
  page = 1,
  perPage = 10
) => {
  return await api.get<IPagination<IOrderServiceFile>>(
    `/service-orders/files/${serviceOrderId}`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
};

const OrderServiceFiles: FC = () => {
  const itemsTable = useRef<any>();
  const { id } = useParams();

  const [files, setFiles] = useState<IOrderServiceFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      getOrderServiceFiles(String(id), page, pageSize)
        .then(({ data }) => {
          setFiles(data?.data);
          setMeta(data?.meta);
          itemsTable.current?.scrollIntoView({ behavior: "smooth" });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [id, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IOrderServiceFile> = (record) => {
    return [
      {
        key: "1",
        icon: <DownloadOutlined />,
        label: "Baixar",
        conditional: record.name.slice(-3) === "pdf",
        onClick: () => downloader(record.url, record.name),
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        conditional: true,
        danger: true,
        onClick: () => deleteFile(record.id),
      },
    ].filter((item) => item.conditional);
  };

  type FileIcons = {
    [key: string]: () => JSX.Element;
  };

  const fileIcons: FileIcons = {
    png: () => <PNG />,
    jpg: () => <JPG />,
    pdf: () => <PDF />,
  };

  function getFileExtension(fileName: string): string {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  function getIconForFile(fileName: string): JSX.Element | null {
    const extension = getFileExtension(fileName).toLowerCase();
    const IconComponent = fileIcons[extension];

    if (IconComponent) {
      return <IconComponent />;
    }

    return null;
  }

  const deleteFile = async (fileId: string) => {
    try {
      await api.delete(`/service-orders/files/${fileId}`);
      message.success("Arquivo removido com sucesso.");

      handleChangePage(meta.current_page, meta.per_page);
    } catch (error) {
      message.error("Erro ao remover o arquivo.");
    }
  };

  const columns: ColumnsType<IOrderServiceFile> = [
    {
      title: "Nome do arquivo",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {getIconForFile(record.name)}
          <span style={{ marginLeft: 8 }}>{record.name}</span>
        </div>
      ),
    },
    {
      title: "Enviado em",
      dataIndex: "updatedAt",
      key: "updatedAt",
      ellipsis: true,
      render: (text: string) =>
        formatters.customDate(text, "dd/MM/yyyy HH:mm:ss"),
    },
    ActionColumn(actions),
  ];

  return (
    <Content width="calc(100% - 48px)">
      <PageHeader
        style={{ boxShadow: "none", padding: 0, marginBottom: "16px" }}
        width="100%"
        backButton={false}
        title={"Arquivos do contrato"}
      />
      <Table
        ref={itemsTable}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          pageSize: meta.per_page,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={files}
      />
    </Content>
  );
};

export { OrderServiceFiles };
