import { FC } from "react";
import { App, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { IEquipment } from "../../../../types";

import { usePagination } from "../../../../hooks";
import { getServiceHistory } from "../../../../services/repositories";
import { reports } from "../../../../services/reports";
import { Content } from "../../../../components/Content";
import { ActionTable } from "../../../../components/ActionTable";
import { ActionFunction } from "../../../../components/ActionColumn";
import { PageHeader } from "../../../../components/PageHeader";
import { Filter } from "./Filter";
import { NotFound } from "../../../../components/NotFound";

const ServiceHistory: FC = () => {
  const { message } = App.useApp();
  const {
    data: serviceHistory,
    isLoading,
    pagination,
    filter,
    setFilter,
  } = usePagination(getServiceHistory);

  const equipments = serviceHistory.map((history) => history.equipments);

  const columns: ColumnsType<IEquipment> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.match(/\d/g).slice(0, 4).join("")}
          </div>
        );
      },
    },
    {
      title: "Equipamentos",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Serviços",
      dataIndex: "services",
      key: "services",
      ellipsis: true,
      render: (service) => service.length,
    },
  ];

  const actions: ActionFunction<IEquipment> = (record) => {
    return [
      {
        key: "1",
        icon: <PrinterOutlined />,
        label: "Imprimir Registro",
        onClick: () => onPrint(record?.id),
      },
    ];
  };

  const onPrint = (record?: string) => {
    if (record) {
      reports("equipment-history", { clientId: record });
      return;
    }
    if (filter && serviceHistory.length > 0) {
      reports("equipment-history", filter);
      return;
    }
    if (filter && serviceHistory.length === 0) {
      message.info("Não há registros para impressão.");
      return;
    }
    message.error("Você precisa filtar as informações!");
  };

  return (
    <>
      <PageHeader
        title="Relatório de Histórico de serviços"
        backButton={false}
        actions={[
          <Button
            onClick={() => onPrint()}
            key={"print_table"}
            icon={<PrinterOutlined />}
            type="primary"
            disabled={serviceHistory.length === 0}
          >
            Imprimir
          </Button>,
        ]}
        crumb
      />
      <Content margin>
        <Filter onSubmit={(values) => setFilter(values)} filter={filter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          dataSource={equipments?.[0]}
          loading={isLoading}
          columns={columns}
          locale={{
            emptyText: <NotFound />,
          }}
        />
      </Content>
    </>
  );
};

export { ServiceHistory };
